import * as React from "react";
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#AEB0C2"
      d="M16.947 3h-5.674c-.28 0-.548.114-.745.316a1.091 1.091 0 0 0-.309.762v.36H6.3c-.28 0-.547.113-.745.316a1.091 1.091 0 0 0-.308.762v.36H3.053c-.28 0-.547.113-.745.315A1.091 1.091 0 0 0 2 6.954v5.093c0 .285.11.56.308.762.198.202.466.316.745.316h2.194v.36c0 .285.11.56.308.762.198.202.466.316.745.316h3.92v.359c0 .286.11.56.308.762.197.202.465.316.745.316h5.674c.28 0 .547-.114.745-.316.197-.202.308-.476.308-.762V4.078c0-.286-.11-.56-.308-.762A1.04 1.04 0 0 0 16.947 3ZM3.053 12.046V6.955h2.194v5.093H3.053ZM6.3 13.485V5.516h3.92v7.968H6.3Zm4.973 1.438V4.078h5.674v10.844h-5.674Z"
    />
  </svg>
);
export default SvgComponent;
