import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 682 469"
    x="0px"
    y="0px"
    width="100%"
    enableBackground="new 0 0 682 469"
    {...props}
  >
    <path
      fill="#FCFCFD"
      opacity="1.000000"
      stroke="none"
      d="M433 470c-142.833 0-285.167-.017-427.5.094-3.75.003-4.597-.845-4.594-4.594.11-153.333.11-306.667 0-460C.903 1.75 1.751.905 5.5.907c224.333.104 448.667.104 673 0 3.75-.002 4.597.844 4.594 4.593-.11 153.333-.11 306.667 0 460 .003 3.75-.844 4.601-4.594 4.595C596.833 469.97 515.167 470 433 470z"
    />
    <path
      className={"theme-icon"}
      opacity="1.000000"
      stroke="none"
      d="M366 8.822c18 0 35.5-.008 53 .002 50.197.027 87.883 37.427 88.224 87.544.065 9.566.06 9.558 8.904 5.536 36.156-16.443 72.264-32.994 108.492-49.28 23.223-10.44 48.797 2.2 54.807 26.746.92 3.758 1.05 7.582 1.05 11.413.01 97.5.032 194.998-.009 292.498-.01 25.078-17.571 42.632-41.511 41.716-5.755-.22-11.079-2.044-16.266-4.405a256991.807 256991.807 0 0 0-110.278-50.167c-5.167-2.35-5.274-2.328-5.17 3.291.632 34.468-13.133 61.28-42.622 79.498-13.014 8.04-27.4 11.938-42.726 11.943-110.999.029-221.999.203-332.997-.074-45.384-.113-82.305-35.701-85.101-80.903-.165-2.66-.264-5.328-.265-7.993-.013-92.832-.141-185.665.053-278.498.078-37.307 17.078-64.557 50.643-81.085 11.68-5.751 24.242-7.835 37.275-7.823 91.332.079 182.665.041 274.497.041z"
    />
    <path
      fill="#FFF"
      opacity="1.000000"
      stroke="none"
      d="M63.957 65.937c8.068-6.9 17.095-9.994 27.348-9.99 109.481.035 218.962-.014 328.444.05 23.383.013 40.257 17.016 40.27 40.463.052 93.65.05 187.301.002 280.952-.012 23.71-16.89 40.588-40.647 40.598-109.315.05-218.63.05-327.945 0-23.762-.01-40.557-16.789-40.574-40.614-.067-93.65-.003-187.302-.058-280.952-.007-12.009 4.164-22.069 13.16-30.507zM507.569 183c0-7.662.056-14.825-.033-21.986-.027-2.19.395-3.605 2.682-4.64 40.397-18.268 80.747-36.637 121.115-54.968.417-.19.937-.15 1.635-.251V372.5c-1.405.98-2.47-.304-3.514-.777-39.476-17.886-78.906-35.873-118.414-53.688-3.053-1.376-3.526-3.2-3.521-6.12.073-42.805.05-85.61.05-128.916z"
    />
  </svg>
);
export default SvgComponent;
