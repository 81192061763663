import * as React from "react";
const SvgComponent = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
    <path
      className={"theme-secondary-icon"}
      d="M9 1.5C4.867 1.5 1.5 4.867 1.5 9s3.367 7.5 7.5 7.5 7.5-3.367 7.5-7.5S13.133 1.5 9 1.5Zm3 8.063H9.562V12a.567.567 0 0 1-.562.563.567.567 0 0 1-.563-.563V9.562H6A.567.567 0 0 1 5.437 9c0-.307.255-.563.563-.563h2.438V6c0-.308.255-.563.562-.563.307 0 .563.255.563.563v2.438H12c.307 0 .563.255.563.562a.567.567 0 0 1-.563.563Z"
    />
  </svg>
);
export default SvgComponent;
