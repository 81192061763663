import * as React from "react";
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={18} fill="none" {...props}>
    <path
      fill="#AEB0C2"
      d="m16.95 13.418-2.113-8.179c-.181-.703-.81-1.202-1.555-1.263l-.012-.233c-.05-.92-.864-1.638-1.849-1.59l-2.227.107-.013-.19C9.12 1.148 8.291.448 7.313.503c-.47.028-.9.228-1.211.564-.31.335-.466.766-.437 1.213.003.052.016.1.033.147l-4.024.192c-.97.046-1.72.835-1.672 1.757l.489 9.268c.024.447.23.858.578 1.158.324.279.744.434 1.181.434.03 0 .06-.002.088-.003l2.302-.11.284 1.101c.194.75.897 1.276 1.71 1.276.141 0 .283-.016.421-.049l8.602-2.008c.944-.22 1.523-1.129 1.292-2.025ZM6.836 2.21a.53.53 0 0 1 .145-.404.591.591 0 0 1 .404-.188c.331-.018.603.215.623.522l.012.176-1.202.058a.487.487 0 0 0 .018-.164ZM2.28 14.087h-.03a.592.592 0 0 1-.394-.129.532.532 0 0 1-.192-.378l-.489-9.264c-.015-.308.234-.568.558-.584l6.362-.302.137 2.052a.538.538 0 0 1-.146.406.6.6 0 0 1-.405.188.603.603 0 0 1-.424-.138.547.547 0 0 1-.198-.385c-.02-.307-.29-.538-.623-.522a.57.57 0 0 0-.55.592c.03.447.24.856.593 1.15a1.8 1.8 0 0 0 1.275.416c.47-.027.901-.227 1.212-.564a1.62 1.62 0 0 0 .436-1.212l-.135-2.04 2.243-.106c.312 0 .57.233.585.53l.489 9.269c.016.307-.234.555-.558.57l-9.746.45Zm13.098.272-8.603 2.007c-.315.072-.633-.114-.709-.409l-.23-.892 6.252-.297c.97-.046 1.72-.835 1.67-1.757l-.416-7.89c.17.065.308.203.355.383l2.112 8.178a.56.56 0 0 1-.431.676Z"
    />
  </svg>
);
export default SvgComponent;
