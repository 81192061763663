import * as React from "react";
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={22} viewBox="0 0 21 22" fill="none" {...props}>
    <path
      className={"theme-icon"}
      d="M20.937 16.717 18.328 6.133a2.16 2.16 0 0 0-1.92-1.635l-.016-.3a2.165 2.165 0 0 0-2.283-2.058l-2.751.138-.016-.246A2.173 2.173 0 0 0 9.034.004a2.16 2.16 0 0 0-1.496.73 2.155 2.155 0 0 0-.54 1.57c.004.066.02.13.04.19l-4.97.248A2.174 2.174 0 0 0 .002 5.016L.607 17.01c.029.578.283 1.11.713 1.498.4.36.92.562 1.46.562l.108-.003 2.844-.143.351 1.425a2.17 2.17 0 0 0 2.633 1.588l10.625-2.599a2.17 2.17 0 0 0 1.596-2.62v-.001ZM8.445 2.213a.705.705 0 0 1 .18-.523.717.717 0 0 1 .498-.243.72.72 0 0 1 .77.676l.015.228-1.484.074a.66.66 0 0 0 .021-.212Zm-5.629 15.37H2.78a.71.71 0 0 1-.487-.166.7.7 0 0 1-.237-.49L1.452 4.938a.724.724 0 0 1 .688-.755l7.86-.39.168 2.655a.717.717 0 0 1-.179.524.728.728 0 0 1-1.269-.433.725.725 0 0 0-1.449.09c.037.579.297 1.108.733 1.49a2.164 2.164 0 0 0 1.575.538 2.16 2.16 0 0 0 1.496-.73c.384-.435.575-.992.539-1.57l-.167-2.638 2.771-.138c.385 0 .704.301.723.687l.603 11.994a.708.708 0 0 1-.688.738l-12.04.583Zm16.18.352L8.369 20.533a.73.73 0 0 1-.876-.53L7.21 18.85l7.723-.385a2.174 2.174 0 0 0 2.064-2.274l-.515-10.21a.724.724 0 0 1 .439.496l2.609 10.583a.725.725 0 0 1-.533.875Z"
    />
  </svg>
);
export default SvgComponent;
