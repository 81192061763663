import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#AEB0C2"
      d="M10.5 10.835a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667ZM13.833 10.835a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667ZM7.167 10.835a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667Z"
    />
    <path
      fill="#AEB0C2"
      d="M16.392 4.108a8.333 8.333 0 0 0-13.567 9.167c.08.166.106.352.075.533l-.733 3.525a.834.834 0 0 0 .504.942.831.831 0 0 0 .329.058h.167l3.566-.716a1.05 1.05 0 0 1 .534.075 8.333 8.333 0 0 0 9.166-13.567l-.041-.017Zm.691 6.967a6.666 6.666 0 0 1-9.166 5.067 2.717 2.717 0 0 0-1.042-.217 2.852 2.852 0 0 0-.467.042l-2.35.475.475-2.35a2.575 2.575 0 0 0-.175-1.509 6.667 6.667 0 1 1 12.493-4.63 6.666 6.666 0 0 1 .232 3.122Z"
    />
  </svg>
)
export default SvgComponent
