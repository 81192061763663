import * as React from "react";
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={16} fill="none" {...props}>
    <path
      className={"theme-icon"}
      d="M17.486 9.332c-2.786 3.092-5.626 4.665-8.472 4.665a8.148 8.148 0 0 1-2.517-.406l-.115-.047-.074-.053a.665.665 0 0 1-.067-1.033l1.642-1.626a.558.558 0 0 1 .094-.08l.108-.067.074-.033a.6.6 0 0 1 .222-.047h.141c.157.038.317.058.478.06.714 0 1.399-.28 1.903-.78.505-.5.789-1.179.789-1.886a2.12 2.12 0 0 0-.047-.46.729.729 0 0 1 0-.12.584.584 0 0 1 .047-.22.247.247 0 0 1 .034-.073.582.582 0 0 1 .067-.106.545.545 0 0 1 .08-.093l2.396-2.373a.678.678 0 0 1 .633-.16h.067l.115.067.094.047c.826.658 1.598 1.38 2.308 2.159a1.988 1.988 0 0 1 0 2.665Zm-10.39 1.5-5.02 4.97A.676.676 0 0 1 .92 15.33c0-.178.071-.348.199-.473L3.77 12.23A17.694 17.694 0 0 1 .514 9.332a1.988 1.988 0 0 1 0-2.665C4.639 2.082 8.852.869 13.038 3.055L15.925.196a.68.68 0 0 1 1.13.3.665.665 0 0 1-.175.646l-9.784 9.69Zm-.418-1.5L10.346 5.7a2.716 2.716 0 0 0-2.253-.18 2.679 2.679 0 0 0-1.598 1.582 2.643 2.643 0 0 0 .183 2.23Z"
    />
  </svg>
);
export default SvgComponent;
