import * as React from "react";
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={18} fill="none" {...props}>
    <path
      fill="#AEB0C2"
      d="M3.44 12.327c.693 0 1.367-.237 1.92-.672a3.32 3.32 0 0 0 1.142-1.738h2.051l2.179 2.548a3.42 3.42 0 0 0-.466 3.007c.323 1.021 1.102 1.816 2.09 2.132a3.09 3.09 0 0 0 2.882-.543 3.367 3.367 0 0 0 1.238-2.76 3.342 3.342 0 0 0-1.45-2.645 3.082 3.082 0 0 0-2.916-.296l-2.007-2.346 2.005-2.347a3.096 3.096 0 0 0 2.93-.294 3.357 3.357 0 0 0 1.46-2.657A3.381 3.381 0 0 0 15.252.943a3.103 3.103 0 0 0-2.896-.546c-.993.318-1.776 1.117-2.1 2.144a3.437 3.437 0 0 0 .472 3.021L8.553 8.11h-2.05a3.303 3.303 0 0 0-1.387-1.912 3.085 3.085 0 0 0-2.264-.441 3.172 3.172 0 0 0-1.959 1.26 3.407 3.407 0 0 0-.63 2.304c.074.823.441 1.587 1.029 2.143a3.12 3.12 0 0 0 2.148.863Zm9.85.602c.384 0 .752.159 1.024.441.271.283.424.666.424 1.065 0 .4-.153.783-.424 1.065a1.42 1.42 0 0 1-1.024.441 1.42 1.42 0 0 1-1.025-.441 1.536 1.536 0 0 1-.424-1.065c0-.4.153-.782.424-1.065.272-.282.64-.44 1.024-.44Zm0-10.843c.384 0 .752.159 1.024.441.271.283.424.666.424 1.065 0 .4-.153.783-.424 1.065-.272.282-.64.441-1.024.441a1.42 1.42 0 0 1-1.025-.44 1.537 1.537 0 0 1-.424-1.066c0-.4.153-.782.424-1.065a1.42 1.42 0 0 1 1.024-.44ZM3.44 7.508c.384 0 .753.158 1.024.44.272.283.425.666.425 1.066s-.153.782-.425 1.065a1.42 1.42 0 0 1-1.024.44c-.384 0-.752-.158-1.024-.44a1.538 1.538 0 0 1-.424-1.065c0-.4.152-.783.424-1.065.272-.283.64-.441 1.024-.441Z"
    />
  </svg>
);
export default SvgComponent;
