import * as React from "react";
const SvgComponent = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      className={"theme-icon"}
      fillRule="evenodd"
      d="M4.5 3.242a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-15a1.5 1.5 0 0 0-1.5-1.5h-15Zm4.02 4.003c.006.956-.71 1.545-1.559 1.541a1.502 1.502 0 0 1-1.493-1.54 1.493 1.493 0 0 1 1.54-1.504c.88.02 1.518.665 1.513 1.503Zm3.76 2.759H9.758v8.56h2.664V17.224c-.002-1.014-.003-2.029.003-3.042.001-.246.012-.502.075-.737.238-.877 1.027-1.444 1.907-1.305.566.089.94.416 1.097.95.097.332.14.691.145 1.039.012 1.047.01 2.095.008 3.142V18.562h2.671V17.001c-.001-1.13-.002-2.259.001-3.389a6.039 6.039 0 0 0-.178-1.507c-.187-.734-.574-1.342-1.203-1.78-.445-.313-.935-.514-1.482-.536a15.191 15.191 0 0 1-.188-.01c-.28-.015-.564-.03-.831.024-.765.153-1.437.503-1.945 1.12-.06.071-.117.143-.203.25l-.02.025v-1.194Zm-6.598 8.563h2.65v-8.558h-2.65v8.558Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
