import * as React from "react";
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={15} fill="none" {...props}>
    <path
      className={"theme-icon"}
      d="M2.552 10.162c.555-.001 1.094-.19 1.536-.538.442-.347.763-.836.914-1.39h1.64l1.743 2.038a2.737 2.737 0 0 0-.372 2.405 2.6 2.6 0 0 0 1.672 1.706c.791.253 1.65.09 2.306-.434a2.694 2.694 0 0 0 .99-2.208 2.673 2.673 0 0 0-1.16-2.116 2.465 2.465 0 0 0-2.333-.237L7.882 7.511l1.604-1.877a2.477 2.477 0 0 0 2.344-.236 2.686 2.686 0 0 0 1.168-2.125 2.705 2.705 0 0 0-.995-2.219A2.483 2.483 0 0 0 9.686.618a2.611 2.611 0 0 0-1.68 1.715 2.75 2.75 0 0 0 .377 2.417l-1.74 2.038H5.002a2.643 2.643 0 0 0-1.11-1.53 2.468 2.468 0 0 0-1.81-.353A2.538 2.538 0 0 0 .514 5.914 2.726 2.726 0 0 0 .01 7.757 2.69 2.69 0 0 0 .833 9.47c.47.445 1.083.691 1.72.69Zm7.88.481c.307 0 .602.127.819.353.217.226.34.533.34.852 0 .32-.123.626-.34.852a1.137 1.137 0 0 1-.82.353c-.307 0-.601-.127-.819-.353a1.229 1.229 0 0 1-.34-.852c0-.32.123-.626.34-.852.218-.226.512-.353.82-.353Zm0-8.674c.307 0 .602.127.819.353.217.226.34.532.34.852 0 .32-.123.626-.34.852a1.137 1.137 0 0 1-.82.352c-.307 0-.601-.126-.819-.352a1.23 1.23 0 0 1-.34-.852c0-.32.123-.626.34-.852.218-.226.512-.353.82-.353Zm-7.88 4.337c.308 0 .602.127.82.353.217.226.339.532.339.852 0 .32-.122.626-.34.852a1.137 1.137 0 0 1-.819.353c-.307 0-.602-.127-.82-.353a1.23 1.23 0 0 1-.339-.852c0-.32.123-.626.34-.852.217-.226.512-.353.82-.353Z"
    />
  </svg>
);
export default SvgComponent;
