import * as React from "react";
const SvgComponent = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      className={"theme-icon"}
      d="M17.176 4.242h2.76l-6.03 6.778L21 20.242h-5.554l-4.35-5.593-4.979 5.593H3.355l6.45-7.249L3 4.243h5.695l3.933 5.112 4.548-5.113Zm-.969 14.376h1.53L7.864 5.78h-1.64l9.983 12.837Z"
    />
  </svg>
);
export default SvgComponent;
