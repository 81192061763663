import * as React from "react";
const SvgComponent = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g className={"theme-icon"}>
      <path
        id="Vector"
        d="M4 2.66685V13.3335C3.99997 13.4521 4.03158 13.5686 4.09159 13.6709C4.15159 13.7733 4.23781 13.8577 4.34135 13.9156C4.44489 13.9735 4.562 14.0027 4.68059 14.0002C4.79918 13.9978 4.91497 13.9637 5.016 13.9015L13.6827 8.56819C13.7797 8.50854 13.8599 8.42502 13.9155 8.32558C13.9711 8.22614 14.0003 8.11412 14.0003 8.00019C14.0003 7.88626 13.9711 7.77423 13.9155 7.6748C13.8599 7.57536 13.7797 7.49183 13.6827 7.43219L5.016 2.09885C4.91497 2.0367 4.79918 2.00262 4.68059 2.00015C4.562 1.99767 4.44489 2.02688 4.34135 2.08476C4.23781 2.14265 4.15159 2.22711 4.09159 2.32943C4.03158 2.43175 3.99997 2.54823 4 2.66685Z"
        className={"theme-icon"}
      />
    </g>
  </svg>
);
export default SvgComponent;
