import * as React from "react";
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={11} height={15} viewBox="0 0 11 15" fill="none" {...props}>
    <path
      className={"theme-icon"}
      fillRule="evenodd"
      d="M7.2 0c.417 0 .816.158 1.11.438l2.229 2.123c.295.281.461.663.461 1.061v.128H7.857c-.434 0-.785-.336-.785-.75V0H7.2ZM5.894 11.25H1.964a.384.384 0 0 0-.393.375c0 .207.176.375.393.375h3.929a.384.384 0 0 0 .393-.375.384.384 0 0 0-.393-.375ZM9.036 9H1.964a.384.384 0 0 0-.393.375c0 .207.176.375.393.375h7.072a.384.384 0 0 0 .393-.375A.384.384 0 0 0 9.036 9Zm0-2.25H1.964a.384.384 0 0 0-.393.375c0 .207.176.375.393.375h7.072a.384.384 0 0 0 .393-.375.384.384 0 0 0-.393-.375ZM6.286 3c0 .828.703 1.5 1.571 1.5H11v9c0 .829-.704 1.5-1.571 1.5H1.57C.703 15 0 14.329 0 13.5v-12C0 .67.703 0 1.571 0h4.715v3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
