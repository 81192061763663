import * as React from "react";
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={14} fill="none" {...props}>
    <path
      className={"theme-icon"}
      d="M12.656 1.75H8.541L6.88.359A1.562 1.562 0 0 0 5.887 0H2.345C1.722 0 1.126.23.686.64.246 1.05 0 1.608 0 2.189v9.624c0 .58.247 1.137.686 1.547.44.41 1.036.641 1.658.641h10.312c.622 0 1.218-.23 1.657-.64.44-.41.687-.967.687-1.547V3.936c0-.58-.247-1.136-.687-1.546a2.431 2.431 0 0 0-1.657-.641Zm.938 10.063a.846.846 0 0 1-.275.618.973.973 0 0 1-.663.257H2.344a.973.973 0 0 1-.663-.257.846.846 0 0 1-.275-.618V2.187c0-.232.099-.454.275-.618a.973.973 0 0 1 .663-.256h3.6l1.66 1.4.402.332h4.688c.248 0 .487.092.663.256a.847.847 0 0 1 .274.619l-.037 7.893Z"
    />
    <path
      className={"theme-icon"}
      d="m6.715 5.227 1.679 1.907H4.615v.875h3.779L6.715 9.917l.732.56 2.55-2.896-2.55-2.914-.732.56Z"
    />
  </svg>
);
export default SvgComponent;
