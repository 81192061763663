import * as React from "react";
const SvgComponent = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      className={"theme-icon"}
      fillRule="evenodd"
      d="M16 3.242H8a5 5 0 0 0-5 5v8a5 5 0 0 0 5 5h8a5 5 0 0 0 5-5v-8a5 5 0 0 0-5-5Zm3.25 13a3.26 3.26 0 0 1-3.25 3.25H8a3.26 3.26 0 0 1-3.25-3.25v-8A3.26 3.26 0 0 1 8 4.992h8a3.26 3.26 0 0 1 3.25 3.25v8Zm-2.5-7.75a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM12 7.742a4.5 4.5 0 1 0 4.5 4.5 4.49 4.49 0 0 0-4.5-4.5Zm-2.75 4.5a2.75 2.75 0 1 0 5.5 0 2.75 2.75 0 0 0-5.5 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
