import * as React from "react";
const SvgComponent = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      className={"theme-stroke-icon"}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.939 6.94A1.5 1.5 0 1 0 9.06 9.06m2.45 2.445A6.538 6.538 0 0 1 8 12.5C5.3 12.5 3.05 11 1.25 8c.954-1.59 2.034-2.758 3.24-3.505m2.145-.86c.45-.09.907-.136 1.365-.135 2.7 0 4.95 1.5 6.75 4.5a12.43 12.43 0 0 1-1.604 2.152M1.25 1.25l13.5 13.5"
    />
  </svg>
);
export default SvgComponent;
