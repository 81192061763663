import * as React from "react";
const SvgComponent = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
    <g className={"theme-icon"}>
      <path d="M40 20a20 20 0 1 1-40 0 20 20 0 0 1 40 0ZM2.857 20a17.143 17.143 0 1 0 34.287 0 17.143 17.143 0 0 0-34.287 0Z" />
      <path d="M27.276 18.569v2.857h-5.848v5.847h-2.857v-5.847h-5.847v-2.857h5.847V12.72h2.857v5.848h5.848Z" />
    </g>
  </svg>
);
export default SvgComponent;
