import * as React from "react";
const SvgComponent = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <path
      className={"theme-stroke-icon"}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.465 15.61c-.255.09-.675.09-.93 0C6.36 14.869 1.5 11.77 1.5 6.52c0-2.317 1.868-4.192 4.17-4.192 1.365 0 2.572.66 3.33 1.68a4.147 4.147 0 0 1 3.33-1.68c2.303 0 4.17 1.875 4.17 4.193 0 5.25-4.86 8.347-7.035 9.09Z"
    />
  </svg>
);
export default SvgComponent;
