import * as React from "react";
const SvgComponent = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      className={"theme-icon"}
      d="M22 12.303c0-5.556-4.477-10.06-10-10.06S2 6.746 2 12.302c0 5.022 3.657 9.184 8.438 9.94v-7.031h-2.54v-2.909h2.54v-2.216c0-2.522 1.492-3.915 3.777-3.915 1.094 0 2.238.197 2.238.197v2.476h-1.26c-1.243 0-1.63.775-1.63 1.571v1.887h2.773l-.443 2.909h-2.33v7.03c4.78-.755 8.437-4.917 8.437-9.939Z"
    />
  </svg>
);
export default SvgComponent;
