import * as React from "react";
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={12} fill="none" {...props}>
    <path
      className={"theme-icon"}
      d="M2.536 3.932H1.31c-.17 0-.307.126-.307.281 0 .155.137.281.307.281h1.225c.169 0 .306-.126.306-.28 0-.156-.137-.282-.306-.282ZM1.923 5.898a.27.27 0 0 0-.09-.198.32.32 0 0 0-.216-.082H.392c-.17 0-.307.125-.307.28 0 .156.138.281.307.281h1.225a.32.32 0 0 0 .216-.082.27.27 0 0 0 .09-.199ZM2.536 7.303H1.31c-.17 0-.307.126-.307.28 0 .156.137.282.307.282h1.225c.169 0 .306-.126.306-.281 0-.155-.137-.281-.306-.281ZM13.777 2.61 11.021.084A.32.32 0 0 0 10.805 0H6.211a.963.963 0 0 0-.65.247.809.809 0 0 0-.269.596v.561H4.68a.963.963 0 0 0-.65.247.809.809 0 0 0-.27.596v8.707a.81.81 0 0 0 .27.596.963.963 0 0 0 .65.247h6.738a.963.963 0 0 0 .65-.247.809.809 0 0 0 .269-.596v-.562h.612a.963.963 0 0 0 .65-.246.809.809 0 0 0 .269-.596V2.809a.27.27 0 0 0-.09-.198ZM11.111.96l1.711 1.568h-1.404c-.17 0-.307-.126-.307-.281V.959Zm.613 9.994a.27.27 0 0 1-.09.199.32.32 0 0 1-.216.082H4.68c-.17 0-.307-.126-.307-.28V2.246c0-.155.137-.28.307-.28h.612V9.55c0 .223.097.438.27.596a.963.963 0 0 0 .649.246h5.513v.562Zm1.531-1.404a.27.27 0 0 1-.09.198.32.32 0 0 1-.216.083H6.211c-.17 0-.306-.126-.306-.281V.843c0-.156.137-.281.306-.281h4.288v1.685c0 .223.097.438.269.596a.963.963 0 0 0 .65.247h1.837v6.46Z"
    />
    <path
      className={"theme-icon"}
      d="M6.823 4.213a.27.27 0 0 0 .09.199.32.32 0 0 0 .217.082h4.9c.17 0 .306-.126.306-.28 0-.156-.137-.282-.306-.282h-4.9c-.17 0-.307.126-.307.281ZM12.03 5.337h-4.9c-.17 0-.307.125-.307.28 0 .156.137.281.307.281h4.9c.17 0 .306-.125.306-.28 0-.156-.137-.281-.306-.281ZM12.03 6.741H9.58c-.17 0-.307.126-.307.28 0 .156.138.282.307.282h2.45c.169 0 .306-.126.306-.281 0-.155-.137-.28-.306-.28Z"
    />
  </svg>
);
export default SvgComponent;
