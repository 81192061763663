import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      className={"theme-icon"}
      d="M256 276.673c-28.016 0-50.81 22.793-50.81 50.81 0 13.895 5.775 27.33 15.857 36.891v45.875c0 19.273 15.68 34.953 34.953 34.953s34.953-15.68 34.953-34.953v-45.875c10.078-9.555 15.857-22.993 15.857-36.891 0-28.017-22.793-50.81-50.81-50.81zm17.98 69.885c-4.851 4.571-7.633 10.96-7.633 17.53v46.161c0 5.705-4.64 10.345-10.345 10.345-5.705 0-10.345-4.64-10.345-10.345v-46.161c0-6.57-2.782-12.96-7.63-17.527-5.304-5.003-8.226-11.778-8.226-19.078 0-14.447 11.755-26.202 26.202-26.202s26.202 11.755 26.202 26.202c-.003 7.3-2.925 14.075-8.225 19.075z"
    />
    <path
      className={"theme-icon"}
      d="M404.978 209.876h-236.44v-97.804c0-48.227 39.234-87.464 87.462-87.464s87.463 39.237 87.463 87.464v44.268c0 6.795 5.51 12.304 12.304 12.304s12.304-5.508 12.304-12.304v-44.268C368.071 50.275 317.796 0 256 0S143.929 50.275 143.929 112.072v97.804h-36.908c-20.353 0-36.911 16.559-36.911 36.911v228.301c0 20.353 16.558 36.911 36.911 36.911h297.957c20.353 0 36.911-16.558 36.911-36.911v-228.3c.001-20.353-16.558-36.912-36.911-36.912zm12.304 265.213c0 6.784-5.519 12.304-12.304 12.304H107.022c-6.784 0-12.304-5.519-12.304-12.304V246.788c0-6.784 5.52-12.304 12.304-12.304h297.957c6.784 0 12.304 5.519 12.304 12.304v228.301z"
    />
  </svg>
);
export default SvgComponent;
